
@import "vars";



.dok {
	margin-bottom: 10px;

	.urd-line {
		.ktg a {
			position: relative;
			z-index: 3;
		}
	}
}

.ReakcePrispevek {
	float:left;
	padding:0 10px;
	margin:10px;
	width:90%;
	text-align:left;
}

.ReakcePrispevek .prispevky li {
	list-style-image:url(#{$cestaVismoWeb}dd.gif);
	
	&.dnesni {
		list-style-image:url(#{$cestaVismoWeb}d0.gif);
	} 
	
	&.vcerejsi {
		list-style-image:url(#{$cestaVismoWeb}d1.gif);
	}
} 

#wdoc p {
	margin-top:0;
}

span.zacerneni {
	background-color:black!important;
	color:black!important;
}


/*uredni deska - premisteno z tabulka.css*/
h4.ud,
.udz {
	margin-top:20px;
	margin-bottom:-5px;
}

h4.ud {
	float:left;
	padding-left:0;
	font-weight:normal;
}	

.udz {
	float:right;
}

.nastaveni {
	padding:0 0 14px 14px;
	margin:5px 0 2.5em 0;
	
	.fvlevo {
		padding-top:27px;
		width:73%;
		overflow:hidden;
		float:left;
	}
	
	h4.cvi {
		float:left;
		margin:0;
		padding:10px 0 4px 0;
	}
	
	#stred & form {
		margin:3px 0;
		padding:0;
	}
	
	hr {
		display:none;
	}
	
	.fvpravo {
		float:right;
		width:25%;
	}
	
	ul.vyberzobrazeni {
		@extend %reset;
		font-weight:bold;
		
		li {
			display:block;
			float:left;
			clear:both;
			width:100%;
			list-style-type:none;
			margin:0 0 1px 0;
			padding:0;
		}
		
		li * {
			display:block;
			padding:5px 8px 5px 10px;
		}
	}

	a.napoveda {
		float:none;height:1.5em;
		margin-left:10px;
	}

	label {
		float:left;
		width:20%;
	}
	
	.fbtn {
		margin-top:1px;
	}
	
	.fbtn,
	.fbtn div {
		display:inline;
		float:left;
		clear:none;
		margin:0;
	}
	
	.btn {
		width:7em;
	}
	
	.format {
		@include box-sizing;
		width:48%;
		float:left;
		margin:1px 5px 1px 0;
	}

	#celek & .zobrazeno {
		margin-top:-17px;
	}
}


