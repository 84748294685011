@charset "UTF-8";
/*-------------zakladni styly pro stranky dalsi urovne--------------*/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
.nastaveni ul.vyberzobrazeni, .formular #elementsChoiceArea, .vzzalozky li, #isvs .hodnota li, .galerie-2016 .ui li, .nahledy li, .unp, .zarovnani p, .vzzalozky ul, .galerie-2016 .ui, .ui {
  margin: 0;
  padding: 0;
}

.vzzalozky ul, .galerie-2016 .ui, .ui {
  list-style-type: none;
}

.kontext2 h3 {
  position: absolute;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  outline: 0;
}

.formular #definitionsArea.format li .lblok fieldset,
.formular #definitionsArea.format li .lblok .format,
.formular #definitionsArea.format li .pblok fieldset,
.formular #definitionsArea.format li .pblok .format, .formular #definitionsArea.format, .formular .lblok,
.formular .pblok, .galerie-2016 .ui li, .nahledy div > a, .fkont form {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

form #skryvaci .fdatum::after, .galerie-2016 .ui::after, .nahledy::after, .fkont .fvyraz::after,
.fkont .fpredkladatel::after,
.fkont .fcheckbox::after,
.fkont fieldset::after, .fvlevo::after,
.fvpravo::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
.format {
  transition: border-color 0.15s ease-out;
  border-radius: 0;
  -webkit-border-radius: 0;
  box-shadow: inset 0 0 0 black;
  -webkit-box-shadow: inset 0 0 0 black;
  width: 100%;
  background-image: none;
  padding: 0 8px;
  margin: 0.25em 0 0 0;
}
.format:focus {
  box-shadow: inset 0 0 0 black;
  -webkit-box-shadow: inset 0 0 0 black;
  outline: 0;
}

input[type=text].format {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type=file].format {
  padding-left: 0;
}

input[type=radio],
input[type=checkbox] {
  vertical-align: middle;
  margin: 0 8px 0 0;
}

textarea.format {
  resize: vertical;
  padding: 8px;
}

select.format,
select.format:focus {
  padding-right: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23444444' d='M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z'/%3E%3C/svg%3E");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

select.inline-form-select::-ms-expand,
select.format::-ms-expand {
  display: none;
}

select.format[multiple] {
  background-image: none;
}

/****************************
* ZÁLOŽKOVÉ RADIO-BUTTONY
****************************/
.ftabs {
  display: inline-table;
  border: 1px #404040 solid;
  border-radius: 3px;
  margin: 0 20px 8px 10px;
  font-size: 0;
}
.ftabs label {
  display: inline;
  margin: 0;
}
.ftabs input[type=radio] {
  position: absolute;
  left: -9999em;
}
.ftabs input[type=radio] + span {
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  cursor: pointer;
  display: table-cell;
  padding: 3px 1em;
  background-color: rgba(0, 0, 0, 0);
}
.ftabs input[type=radio]:checked + span {
  cursor: default;
  background-color: #404040;
  color: #ffffff;
}

/***************************/
/****************************
* POLE S DATEPICKEREM
****************************/
* #dd,
#do,
input#datum {
  padding-right: 42px;
  background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
  background-repeat: no-repeat;
  background-position: 100% 30%;
}
* #dd + .calendar-toggle,
#do + .calendar-toggle,
input#datum + .calendar-toggle {
  padding: 0;
  margin: 5px 0 0;
  border: 0;
  position: absolute;
  right: 37px;
  background-color: transparent;
}

/***************************/
/****************************
* BĚŽNÉ FORMULÁŘE
****************************/
.fvlevo,
.fvpravo {
  margin: 0 0 1em 0;
}

.fvlevo:nth-last-child(2),
.fvpravo:last-child {
  margin-bottom: 0;
}

.nizky .fvlevo:nth-last-child(2) {
  margin-bottom: 1em;
}

@media screen and (min-width: 501px) {
  .fvlevo,
  .fvpravo {
    width: 48.5%;
  }
  .fvlevo {
    float: left;
  }
  .fvpravo {
    float: right;
  }
}
.fkont a:not([href]) {
  text-decoration: none !important;
  line-height: 0;
}
.fkont form {
  padding: 15px 20px 25px 20px;
  margin: 0 0 20px 0;
  position: relative;
}
.fkont label {
  line-height: 1.45;
  font-weight: normal;
  margin-bottom: 0.25em;
}
.fkont #prepinac {
  float: left;
  margin: 0.45em 1em 1em 0;
}
@media screen and (min-width: 501px) {
  .fkont #prepinac {
    margin-bottom: 6px;
  }
}
.fkont #prepinac a {
  text-decoration: underline;
}
.fkont .fvyraz,
.fkont .fpredkladatel,
.fkont .fcheckbox,
.fkont fieldset {
  margin-bottom: 1em;
}
.fkont .fvyraz:last-child,
.fkont .fpredkladatel:last-child,
.fkont .fcheckbox:last-child,
.fkont fieldset:last-child {
  margin-bottom: 0;
}
.fkont fieldset {
  border: 0;
  padding: 0;
}
.fkont .fcheckbox + .fcheckbox,
.fkont .fcheckbox + [type=hidden] + .fcheckbox {
  margin-top: -0.5em;
}
.fkont #search:empty {
  text-decoration: none !important;
  line-height: 0;
  font-size: 0;
}
.fkont .format,
.fkont select,
.fkont label {
  clear: both;
}

.nizky {
  width: 100%;
}
.nizky .sf {
  display: none;
}
.nizky .fvlevo,
.nizky .fvpravo {
  width: auto;
  float: none;
}
@media screen and (min-width: 581px) {
  .nizky form {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  .nizky .fvlevo {
    padding-right: 4%;
  }
  .nizky .fvlevo,
  .nizky .fbtn,
  .nizky .fvpravo {
    float: none;
    display: table-cell;
    vertical-align: bottom;
  }
  .nizky .fbtn,
  .nizky .fvpravo {
    width: 10em;
  }
}

.fbtn {
  text-align: right;
  display: inline;
  float: right;
  white-space: nowrap;
  margin-left: 15px;
}

.fbtn div {
  float: right;
}

.vyhlodkaz {
  margin: 0 0 0.5em 0;
  padding: 0;
}

/**********************
* OSTATNÍ TEXTY
**********************/
form input,
form textarea,
form button,
form label,
form select {
  font-size: 0.9375rem;
}

.bodkazy .ui li {
  line-height: 1.3;
  padding: 1px 0;
  margin: 0 0 0.5em 0;
}
.bodkazy .ui li .hodnota li {
  background-image: none;
  padding: 0;
}
.bodkazy .odkazy .ui li::before {
  left: 5px;
}

/*******************************
* PŘEHLED OSOB
*******************************/
.dok.osoby li {
  padding-left: 146px;
}
.dok.osoby li img {
  margin-left: -146px;
  max-width: 120px;
}
.dok.osoby li.noface::before {
  content: "";
  margin-left: -146px;
  float: left;
  width: 120px;
  height: 120px;
  background: #cccccc url(/aspinclude/vismoweb5/html/noface.svg) no-repeat 50% 50%;
  background-size: 80px auto;
}
@media screen and (max-width: 419px) {
  .dok.osoby li {
    padding-left: 0;
  }
  .dok.osoby li strong img, .dok.osoby li.noface::before {
    float: none;
    display: table;
    margin: 0 0 10px 0;
  }
}

.zarovnani {
  font-size: 0.9375rem;
}
.zarovnani * {
  vertical-align: middle;
  display: inline-block;
}
.zarovnani .strzpet,
.zarovnani .strvpred,
.zarovnani span a {
  height: 29px;
}

.zarovnani p {
  display: inline;
}

.popis {
  margin-bottom: 10px;
  line-height: 150%;
}
#osnova .popis {
  margin-bottom: 30px;
}

.obsah hr.grafika {
  display: none;
}

.kontext2 {
  padding-bottom: 8px;
  padding-top: 50px;
}
.chskryt {
  display: none !important;
}

.perex {
  position: relative;
  padding: 0 0 15px 1px;
}

#celek .dpopis {
  padding-top: 60px;
  line-height: 1.8;
}

.obsah .obsah .popis {
  padding: 0 0 15px 0;
}

.stav,
.rozbalit,
.zobrazit,
.volba,
.dodatek,
.stranka {
  margin: 15px 0;
  padding: 0;
}

.pataprvku {
  margin: 15px 0 30px 0;
}

#zahlavi .zobrazeno {
  font-weight: bold;
  clear: none;
  margin: 0 0 15px 0;
}

.zobrazit,
.volba {
  font-weight: bold;
}

.zobrazit a,
.volba a {
  padding: 0 5px;
}

.unp {
  position: relative;
  top: 0;
  z-index: 10;
}
.unp span {
  float: left !important;
}
.unp img {
  margin: 0 10px 10px 0;
}

@media screen and (max-width: 499px) {
  .unp {
    width: 100%;
    clear: both;
    float: none;
    margin-bottom: 15px;
  }
  .unp a::after {
    content: "";
    font-size: 0;
    display: table;
    width: 100%;
    clear: both;
  }
  .unp img {
    float: none;
  }
  #unpobtekane {
    margin-left: 0 !important;
  }
}
.velkaupoutavka span {
  float: left;
}
.velkaupoutavka img {
  margin: 10px 10px 10px 0;
}

#stred > .obsah {
  position: relative;
}

.adresare,
.dok,
.dok2,
.formulare,
.galerie,
.kategorie,
.osnovy,
.odkazy,
.utvary,
.aktual,
.frm {
  position: relative;
  width: auto;
  overflow: hidden;
  text-align: left;
}

.ui {
  clear: left;
}
.ui li {
  position: relative;
  clear: left;
  padding: 0.12em 0 0 15px;
  margin: 0;
}
.ui li::after {
  content: "";
  font-size: 0;
  display: table;
  clear: both;
}
.ui > li {
  width: auto;
}
.ui strong img {
  margin: 3px 18px 4px 0;
}
.ui img {
  float: left;
  margin: 7px 15px 6px 0;
}
.ui div {
  padding: 5px 0 0 0;
}
.odkazy .ui li.bi, .utvary .ui li.ou {
  background-image: none;
  list-style-type: none;
}
.ui ul li strong {
  font-weight: normal;
}

.navratove {
  margin-bottom: 10px;
}

dl {
  margin: 8px 0 0 0;
  padding: 0 0 0 1px;
}
dl.kontakty ul.ui {
  margin-left: 4px;
}
dl.kontakty ul.ui ul {
  margin-left: 20px;
}

dl dl {
  margin: 8px 0 0 0;
}

dt {
  font-weight: bold;
  margin: 20px 0 0 0;
  padding: 0;
}

dd {
  padding: 3px 0 0 0;
  margin-left: 0;
}

dd ul.ui li {
  margin-top: 0;
}

.ui dd div {
  padding-top: 0;
  line-height: 130%;
}

ul.ui li dl {
  margin-left: 0;
}

.vlozenemenuobsah {
  margin: 0 0 0 15px;
}

#cse-search-results iframe {
  height: 1400px;
}

/* ************ flexibilni galerie (pocet obrazku se prizpusobi sirce stranky) **************** */
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
/*****************************************
* ECCO - FLEXBOX
*****************************************/
.nahledy {
  padding: 15px 0;
  margin: 0;
  list-style-type: none;
  clear: both;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .nahledy {
    display: block;
  }
}
.nahledy li {
  text-align: center;
  margin-bottom: 15px;
}
.nahledy a,
.nahledy a:link,
.nahledy a:visited {
  text-decoration: none;
}
.nahledy a {
  display: block;
  cursor: pointer;
  padding-top: 13px;
}
.nahledy img {
  vertical-align: top;
  margin: 0 auto;
  width: 80%;
  height: auto;
}
.nahledy span {
  display: block;
  padding: 8px;
  float: none;
}
.nahledy li > a {
  width: 90%;
}
.nahledy div {
  height: 100%;
  display: block;
}
.nahledy div > a {
  transition: background-color 0.2s, border-color 0.2s;
}
.nahledy:not(.pridanytext) div > a {
  height: 100%;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui {
  margin-bottom: 30px;
  margin-top: 20px;
}
.galerie-2016 .ui li {
  clear: none;
  margin-bottom: 35px;
}
.galerie-2016 .ui li img {
  margin: 0 0 8px 0;
  float: none;
  display: table;
}
.galerie-2016 .ui li a {
  display: block;
}
.galerie-2016 .ui li.noimage a::before {
  content: "";
  display: block;
  height: 0;
  margin-bottom: 8px;
  padding-bottom: 60%;
  background: #cccccc url(/aspinclude/vismoweb5/html/noimage.svg) no-repeat 50% 50%;
}

.pridanytext p {
  margin: 0;
  padding: 8px;
}
.pridanytext p a {
  height: auto;
  min-height: 10px;
  text-decoration: underline;
  padding-top: 0;
  border: 0 !important;
  background: none !important;
}
.pridanytext p a:hover,
.pridanytext p a:focus,
.pridanytext p a:active {
  text-decoration: none;
}

.fotobig {
  padding-bottom: 15px;
}
.fotobig img {
  line-height: normal;
}
.fotobig .obr img {
  margin-bottom: 10px;
}

#fgzvet .uvodprvku {
  margin-bottom: 15px;
}

#textovelistovani {
  margin-top: 15px;
  display: none;
}
#textovelistovani .aktivni {
  background-color: transparent !important;
}

#listovani {
  position: relative;
  margin: 10px 0;
  border: 0;
}
#listovani #prvni,
#listovani #posledni,
#listovani #zpetfg img {
  display: none;
}
#listovani span {
  display: inline-block;
  vertical-align: middle;
}
#listovani #zpetfg a::after {
  content: "zpět na galerii";
  vertical-align: middle;
  display: inline-block;
}
#listovani a {
  text-decoration: none;
}

.strlistovani img,
#listovani img {
  background-color: #bbbbbb;
  color: #000000;
}
.strlistovani .aktivni img,
#listovani .aktivni img {
  background-color: #555555 !important;
  color: #ffffff;
}
.strlistovani .aktivni:hover img, .strlistovani .aktivni:focus img,
#listovani .aktivni:hover img,
#listovani .aktivni:focus img {
  background-color: #bbbbbb !important;
  color: #000000;
}

.zadani-akce fieldset {
  padding: 1em 1.5em 1.5em 1.5em;
  margin-bottom: 1.5em;
}

/*pouze isvs*/
#isvs dl {
  margin: 6px 0 3px 0;
  padding: 5px 5px 5px 18px;
}
#isvs .ui li {
  margin-top: 10px;
}
#isvs .hodnota {
  margin: 4px 0;
  padding: 5px 0 5px 27px;
}
#isvs .hodnota li {
  line-height: 1.25;
  list-style-type: none;
  background-image: none;
}
#isvs .hodnota div {
  padding-top: 0;
}
#isvs .odsazena {
  padding: 0 0 0 27px;
}
#isvs .odsazena li {
  padding: 5px 0 10px 0;
}

.isvsItemDescription, ul.ui li .isvsItemDescription {
  font-size: 13px;
  font-size: 0.8125rem;
  font-style: italic;
  background-color: #D4D0C8;
  color: #101010;
  padding: 1em;
  margin: 20px 0 0 0;
  border: 1px #C0BCB4 solid;
}
.isvsItemDescription strong, ul.ui li .isvsItemDescription strong {
  font-weight: bold;
}
.isvsItemDescription p {
  margin: 0 0 1em 0;
}
.isvsItemDescription p:last-child {
  margin-bottom: 0;
}
.isvsItemDescription ol li {
  list-style-type: lower-alpha;
}
.isvsItemDescription ul li {
  background-image: none;
}
.isvsItemDescription ul li,
.isvsItemDescription ol li {
  background-image: none;
  padding-top: 0;
  margin-top: 0;
  padding-left: 0;
}
.isvsItemDescription ul li:before,
.isvsItemDescription ol li:before {
  content: normal;
}
.isvsItemDescription ol,
.isvsItemDescription ul {
  margin-top: 0;
  padding-top: 0;
  padding-left: 40px;
}

/*pro vsechny stranky s tabulkou (.seznam - prehled dokumentu, osob, utvaru, anketa, pruvodka, fulltext, ke stažení)*/
.seznam {
  border-collapse: collapse;
  empty-cells: show;
  margin: 0 auto;
  width: 100%;
}
.seznam td,
.seznam th {
  vertical-align: top;
  padding: 6px 10px 7px 10px;
}
.seznam td:first-child,
.seznam th:first-child {
  padding-left: 18px;
}
.seznam th {
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  padding-top: 8px;
}
.seznam th.tddatum {
  width: 10%;
}
.seznam th.tdzodpovida {
  width: 20%;
}
.seznam td img {
  float: left;
  border: 0;
  margin: 6px 8px 2px 0;
}
.seznam td div {
  margin-top: 2px;
}

.cislo {
  white-space: nowrap;
}

.blokposun {
  width: 100%;
  overflow: auto;
  max-width: 100%;
  margin: 10px 0;
}

form.vyber {
  margin: 0;
  padding: 0 0 10px 0;
  border: 0;
}
form.vyber label {
  margin-left: 4px;
  margin-right: 30px;
}
form.vyber input {
  vertical-align: middle;
}

.nadpissekce {
  margin: 10px 0 15px 0;
  padding: 0;
}

.a,
.b,
.c {
  float: left;
  display: inline;
  width: 30%;
}

table.seznam ul {
  margin: 0 0 0 14px;
  padding: 1px;
}

.vypis-pismena {
  padding: 0 0 20px 0;
  margin: 0;
  font-weight: bold;
}
.vypis-pismena li {
  background-image: none;
  border: 0 !important;
}
.vypis-pismena li::before {
  color: #888888;
  font-weight: normal;
}
.vypis-pismena li a {
  text-decoration: underline;
  margin: 0;
}
.vypis-pismena li * {
  line-height: normal;
}

.vzzalozky {
  padding: 15px 0 0 0;
  width: 100%;
}
.vzzalozky ul {
  padding-bottom: 1px;
  font-weight: bold;
  text-transform: uppercase;
}
.vzzalozky li {
  float: left;
  position: relative;
  width: 50%;
  display: block;
  text-align: center;
}
.vzzalozky li span,
.vzzalozky li a {
  display: block;
  list-style-type: none;
  margin: 0;
  border: 2px black solid;
  border-width: 0 0 2px 0;
  padding: 12px 15px 7px 15px;
}
.vzzalozky li.vzazalozka span {
  border-width: 2px 2px 0 2px;
}

.ezakazky .blokposun {
  margin-top: 0;
  padding-top: 4px;
}

#profilzadavatele .zakazky th.sirka {
  width: 19%;
  white-space: normal;
}
#profilzadavatele .zakazky td {
  vertical-align: middle;
}
#profilzadavatele .seznam th.tdzodpovida {
  width: 15%;
}

@font-face {
  font-family: "whinterni";
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.ttf") format("truetype"), url("/ASPINCLUDE/vismoWeb5/html/fonty/whinterni.svg#whinterni") format("svg");
  font-weight: normal;
  font-style: normal;
}
.sassSipka {
  font-family: "whinterni" !important;
  font-size: 14px;
}
.sassSipka::before {
  font-family: "whinterni" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  margin: 0 0.2em 0 0;
  vertical-align: top;
  vertical-align: text-top;
  position: relative;
  top: 0.1em;
  text-rendering: geometricPrecision;
}

.sassSipkaA {
  text-decoration: none !important;
}

.sassSipkaD::before {
  content: "\e000";
}

.sassSipkaDA::before {
  content: "\e001";
}

.sassSipkaN::before {
  content: "\e002";
}

.sassSipkaNA::before {
  content: "\e003";
}

/************************************
*	UŽIVATELSKY SESTAVENÝ FORMULÁŘ
************************************/
.formular {
  /***********************************
  * 20150811 - pro vytvářeč formulářů
  ***********************************/
}
#formular .formular {
  padding: 0 36px;
  margin-top: 20px;
}
.formular .lblok,
.formular .pblok {
  clear: none;
  padding: 5px 3%;
}
.formular .lblok {
  margin-right: 3%;
  float: left;
  text-align: right;
  padding-right: 0;
}
.formular .lblok label {
  font-weight: 700;
  font-size: 1rem;
}
.formular .lblok span {
  font-weight: 400;
  font-size: 0.875rem;
}
.formular .pblok {
  float: right;
  padding-left: 0;
  padding-right: 0;
}
.formular .lblok:empty,
.formular .lblok[style="width:1%;"] {
  display: none;
}
.formular .lblok:empty + .pblok,
.formular .lblok[style="width:1%;"] + .pblok {
  width: 100% !important;
  padding-left: 3%;
  padding-right: 3%;
}
@media screen and (max-width: 519px) {
  .formular .lblok,
  .formular .pblok {
    float: none;
    width: auto !important;
    margin: 0;
    padding: 5px 3%;
    text-align: left;
  }
}
.formular .format[type=file] {
  padding-left: 0;
  padding-top: 0;
}
.formular .lblok input,
.formular .pblok .fradio,
.formular input[type=file],
.formular .btn {
  width: auto;
}
.formular .lblok [type=checkbox] {
  margin-top: 0.5em;
}
.formular .b1,
.formular .b2 {
  width: auto;
  min-width: 300px;
  padding: 15px 0;
}
.formular .b1.last,
.formular .b2.last {
  text-align: center;
  padding: 15px 0;
}
.formular .b1.last .lblok,
.formular .b1.last .pblok,
.formular .b2.last .lblok,
.formular .b2.last .pblok {
  float: none;
}
.formular .b1.last .fbtn2,
.formular .b2.last .fbtn2 {
  display: inline-block;
  float: none;
}
.formular .odkazy {
  padding-left: 10px;
}
.formular hr.oddelovac {
  height: 4px;
}
.formular fieldset label,
.formular fieldset span {
  font-weight: normal;
}
.formular fieldset span {
  display: block;
  margin: 4px 6px;
}
.formular label {
  font-weight: normal;
}
.formular .b1,
.formular .b2 {
  background-color: transparent;
  border-bottom: 2px #ffffff solid;
}
.formular #elementsChoiceArea {
  list-style-type: none;
  position: relative;
}
.formular #elementsChoiceArea .lblok {
  width: 35%;
}
.formular #elementsChoiceArea .pblok {
  width: 55%;
}
.formular #elementsChoiceArea .b1,
.formular #elementsChoiceArea .b2 {
  width: auto;
  min-width: 0px;
}
.formular #elementsChoiceArea .overElm {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: transparent;
  top: 0;
  left: 0;
}
.formular #elementsChoiceArea .pblok.null {
  padding-left: 10px;
}
.formular #elementsChoiceArea .lblok.radiogroup {
  width: 0%;
}
.formular #elementsChoiceArea li {
  position: relative;
}
.formular #definitionsArea.format {
  padding: 5px;
  margin: 10px 0;
  width: 95%;
}
.formular #definitionsArea.format li {
  margin: 0;
}
.formular #definitionsArea.format li .lblok,
.formular #definitionsArea.format li .pblok {
  float: left;
  padding: 5px 2.5%;
  margin: 0;
}
.formular #definitionsArea.format li .lblok fieldset,
.formular #definitionsArea.format li .lblok .format,
.formular #definitionsArea.format li .pblok fieldset,
.formular #definitionsArea.format li .pblok .format {
  width: 100%;
}
.formular #definitionsArea.format li .lblok {
  width: 20%;
}
.formular #definitionsArea.format li .pblok {
  width: 70%;
}
.formular #definitionsArea.format li fieldset {
  margin: 0;
}
.formular #definitionsArea.format .infoText {
  padding: 30px 15px;
  text-align: center;
  list-style-type: none;
}
.formular .lblok #elementsChoiceArea input[type=text],
.formular #elementsChoiceArea input[type=file] {
  width: 95%;
}
.formular .lblok #elementsChoiceArea .pblok {
  text-align: left;
}
#create-form .formular * {
  font-size: 12px;
  font-size: 0.75rem;
}
#create-form .formular .lblok h3.cvi {
  font-size: 20px;
  font-size: 1.25rem;
  border-bottom: 1px rgba(0, 0, 0, 0.3) solid;
  padding: 3px;
  width: auto;
  margin-left: 0;
  font-weight: bold;
}

#create-form #userForm_InfoMessageArea {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.last .lblok,
.last .pblok {
  margin-top: 5px;
  margin-bottom: 5px;
}

form #skryvaci .fdatum + .vyber-archiv {
  margin-top: 8px;
}
form #skryvaci .fdatum + .vyber-archiv br {
  display: none;
}
form #skryvaci .fdatum + .vyber-archiv * {
  vertical-align: middle;
}
form #skryvaci .fdatum + .vyber-archiv input[type=checkbox],
form #skryvaci .fdatum + .vyber-archiv input[type=radio] {
  margin-top: 0;
  margin-right: 5px;
}

/*pro soubory o_osoba.asp a o_utvar.asp a galerie2.asp*/
#utvar .odkazy ul,
#osobnost .odkazy ul {
  margin: 10px 0;
}

.vip {
  float: right;
  position: relative;
  z-index: 10;
  display: inline;
  margin: 0;
  padding: 0 10px 10px 10px;
}
.vip img {
  margin: 0 0 8px 0;
}
.vip strong {
  display: block;
  margin-bottom: 4px;
}

@media screen and (max-width: 579px) {
  .vip {
    float: none;
    display: block;
    width: auto !important;
    margin-bottom: 25px;
  }
  #kvlevo {
    margin-right: 0 !important;
  }
}
.obsah h3 {
  clear: left !important;
}

dl.kontakty {
  width: 100%;
  margin: 25px 0 0 0;
}
dl.kontakty ul {
  margin-left: 20px;
}
dl.kontakty ul ul {
  margin-left: 0;
}
dl.kontakty dd {
  padding-left: 0;
}

hr.cist {
  display: inline;
}

.ddDen {
  display: block;
  float: left;
  width: 4.5em;
}

dd .mapa {
  position: absolute !important;
  max-width: 100%;
}
dd .mapa div {
  margin-top: 0;
}

.mapstop {
  margin-top: 300px !important;
}

.dok {
  margin-bottom: 10px;
}
.dok .urd-line .ktg a {
  position: relative;
  z-index: 3;
}

.ReakcePrispevek {
  float: left;
  padding: 0 10px;
  margin: 10px;
  width: 90%;
  text-align: left;
}

.ReakcePrispevek .prispevky li {
  list-style-image: url(../images/vismo_web/dd.gif);
}
.ReakcePrispevek .prispevky li.dnesni {
  list-style-image: url(../images/vismo_web/d0.gif);
}
.ReakcePrispevek .prispevky li.vcerejsi {
  list-style-image: url(../images/vismo_web/d1.gif);
}

#wdoc p {
  margin-top: 0;
}

span.zacerneni {
  background-color: black !important;
  color: black !important;
}

/*uredni deska - premisteno z tabulka.css*/
h4.ud,
.udz {
  margin-top: 20px;
  margin-bottom: -5px;
}

h4.ud {
  float: left;
  padding-left: 0;
  font-weight: normal;
}

.udz {
  float: right;
}

.nastaveni {
  padding: 0 0 14px 14px;
  margin: 5px 0 2.5em 0;
}
.nastaveni .fvlevo {
  padding-top: 27px;
  width: 73%;
  overflow: hidden;
  float: left;
}
.nastaveni h4.cvi {
  float: left;
  margin: 0;
  padding: 10px 0 4px 0;
}
#stred .nastaveni form {
  margin: 3px 0;
  padding: 0;
}
.nastaveni hr {
  display: none;
}
.nastaveni .fvpravo {
  float: right;
  width: 25%;
}
.nastaveni ul.vyberzobrazeni {
  font-weight: bold;
}
.nastaveni ul.vyberzobrazeni li {
  display: block;
  float: left;
  clear: both;
  width: 100%;
  list-style-type: none;
  margin: 0 0 1px 0;
  padding: 0;
}
.nastaveni ul.vyberzobrazeni li * {
  display: block;
  padding: 5px 8px 5px 10px;
}
.nastaveni a.napoveda {
  float: none;
  height: 1.5em;
  margin-left: 10px;
}
.nastaveni label {
  float: left;
  width: 20%;
}
.nastaveni .fbtn {
  margin-top: 1px;
}
.nastaveni .fbtn,
.nastaveni .fbtn div {
  display: inline;
  float: left;
  clear: none;
  margin: 0;
}
.nastaveni .btn {
  width: 7em;
}
.nastaveni .format {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 48%;
  float: left;
  margin: 1px 5px 1px 0;
}
#celek .nastaveni .zobrazeno {
  margin-top: -17px;
}